<template>
  <div class="settingsPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="tabs">
          <b-nav tabs>
            <b-nav-item to="#" :active="$route.hash === '#' || $route.hash === ''">
              Settings
            </b-nav-item>
            <!-- <b-nav-item to="#emails" :active="$route.hash === '#emails'">
              Emails
            </b-nav-item> -->
            <!-- <b-nav-item to="#privacy" :active="$route.hash === '#privacy'">
              Privacy
            </b-nav-item> -->
            <b-nav-item to="#billing" :active="$route.hash === '#billing'">
              Billing
            </b-nav-item>
          </b-nav>
          <div class="tab-content">
            <div :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="updateForm"
                  >
                    <form
                        class="settings-form"
                        @submit.prevent="handleSubmit(updateProfile)"
                    >
                      <div class="row">
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.first_name"
                              name="first_name"
                              rules="required"
                              label="First Name"
                          />
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.last_name"
                              name="last_name"
                              rules="required"
                              label="Last Name"
                          />
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.phone_number"
                              v-mask="'(###) ###-####'"
                              name="phone"
                              label="Phone Number"
                              rules="required|phone_number"
                          />
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.email"
                              name="email"
                              label="Email Address"
                              rules="required|email"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-lg-3">
                          <TextInput
                              v-model="user.agency.name"
                              name="company_name"
                              rules="required"
                              label="Company name"
                          />
                        </div>
                        <div class="col-md-5 col-lg-6">
                        </div>
                        <div class="col-md-3 align-self-end text-right mt-md-0 mt-3">
                          <b-form-group>
                            <b-button
                                variant="primary"
                                class="btn-dark-blue"
                                :disabled="loadingUpdate || (invalid && touched)"
                                type="submit"
                            >
                              <b-spinner v-if="loadingUpdate" small />
                              <span v-else>Update</span>
                            </b-button>
                          </b-form-group>
                        </div>
                      </div>

                    </form>
                  </ValidationObserver>
                  <!-- <WebHook /> -->
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="apiForm"
                  >
                      <form
                          class="api-form"
                          @submit.prevent="handleSubmit(updateKey)"
                      >
                        <div class="row">
                          <div class="col-12">
                            <label>Open AI API Key</label>
                          </div>
                          <div class="col-md-12">
                            <TextInput
                                v-if="isDisabled"
                                v-model="formattedPassword"
                                disabled
                                class="float-left pr-2"
                                style="width:calc(100% - 40px)"
                                help="Visit <a target='_blank' href='https://platform.openai.com/account/api-keys'>https://platform.openai.com/account/api-keys</a>"
                            />
                            <TextInput
                                v-else
                                v-model="user.api_key"
                                class="float-left pr-2"
                                style="width:calc(100% - 40px)"
                                help="Visit <a target='_blank' href='https://platform.openai.com/account/api-keys'>https://platform.openai.com/account/api-keys</a>"
                            />
                            <button type="button" class="btn btn-link p-0" @click="isDisabled = !isDisabled">
                              <Feather v-if="isDisabled" type="edit" class="icon-lg" />
                              <Feather v-else type="x" class="icon-lg" />
                            </button>
                          </div>
                          <div class="col-md-5 col-lg-6">
                          </div>
                          <div class="col-md-12 align-self-end text-right mt-md-0 mt-3">
                            <b-form-group>
                              <b-button
                                  variant="primary"
                                  class="btn-dark-blue"
                                  :disabled="savingKey || (invalid && touched)"
                                  type="submit"
                              >
                                <b-spinner v-if="savingKey" small />
                                <span v-else>Update</span>
                              </b-button>
                            </b-form-group>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                </div>
              </div>
              <PasswordReset />
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#emails' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="emailsForm"
                  >
                    <form
                        class="emails-form"
                        @submit.prevent="handleSubmit(updateEmails)"
                    >
                      <div class="row">
                        <div class="col mb-3">
                          <label>Email when a lead opts-in</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="user.agency.settings.email_name"
                              name="email_name"
                              rules="required"
                              margin-bottom="0"
                              placeholder="From Name"
                              label="From Name"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextInput
                              v-model="user.agency.settings.email_address"
                              name="email_address"
                              rules="required|email"
                              margin-bottom="0"
                              placeholder="From Name"
                              label="From Email"
                          />
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <vue2-tinymce-editor :id="'email_message' + user.agency.id" v-model="user.agency.settings.email_message"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <b-form-group class="text-right mt-3">
                        <b-button
                            variant="primary"
                            class="btn-dark-blue"
                            :disabled="loadingUpdate || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Update</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#privacy' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="privacyForm"
                  >
                    <form
                        class="privacy-form"
                        @submit.prevent="handleSubmit(updatePrivacy)"
                    >
                      <div class="row">
                        <div class="col">
                          <label>
                            Privacy Policy
                          </label>
                          <vue2-tinymce-editor id="privacy-policy" v-model="user.agency.settings.privacy_policy"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <label>
                            Terms Of Service
                          </label>
                          <vue2-tinymce-editor v-if="privacyReady" :id="'terms-of-service' + user.agency.id" v-model="user.agency.settings.terms_of_service"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <b-form-group class="text-right mt-3">
                        <b-button
                            variant="primary"
                            class="btn-dark-blue"
                            :disabled="loadingUpdate || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Update</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#billing' }]">
              <div class="card">
                <div class="card-body">
                  <SettingsPaymentMethods />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SettingsPaymentMethods from "@components/Agency/PaymentMethods/SettingsPaymentMethods";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import PasswordReset from "@components/Common/PasswordReset";
import WebHook from "@components/Common/WebHook";
export default {
  components:{
    SettingsPaymentMethods,
    Vue2TinymceEditor,
    PasswordReset,
    WebHook
  },
  data() {
    return {
      showVideoModal: false,
      loadingUpdate: false,
      loadingRemove: null,
      showDomainModal: false,
      privacyReady:false,
      savingKey: false,
      isDisabled:true,
      user: {
        agency: {
          whitelabel: {},
          settings:{}
        },
      },
    }
  },
  computed:{
    formattedPassword:{
      get(){
        if(this.user.api_key) {
          return '*'.repeat(this.user.api_key?.length - 7) + this.user.api_key?.slice(-7);
        }else{
          return "Not Set";
        }
      },
      set(value){
        this.user.api_key = value;
      }
    }
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.setupPrivacy();
  },

  methods: {
    async setupPrivacy() {
      // this is stupid but tinymce is creating muliple editors with the same id because they are init at the same time
      await new Promise(resolve => setTimeout(resolve, 500));
      this.privacyReady = true;
    },
    openVideoModal() {
      this.showVideoModal = true
    },

    updateProfile() {
      this.loadingUpdate = true

      let params = {
        company_name: this.user.agency.name,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone_number,
        email: this.user.email,
        notify_initial_searches:this.user.agency.settings.notify_initial_searches,
        notify_new_leads:this.user.agency.settings.notify_new_leads,
        setting_form:'settings'
      }

      this.$store
        .dispatch('agency/editSettings', params)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },
    updatePrivacy(){
      this.loadingUpdate = true

      let params = {
        privacy_policy: this.user.agency.settings.privacy_policy,
        terms_of_service: this.user.agency.settings.terms_of_service,
        setting_form:'privacy'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    updateKey(){
      this.savingKey = true;
      let params = {
        api_key: this.user.api_key
      }
      this.$store
          .dispatch('agency/editKey', params)
          .then(() => {
            this.savingKey = false;
            this.isDisabled = true;
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.savingKey = false
          })
    },
    updateEmails(){
      this.loadingUpdate = true

      let params = {
        email_name: this.user.agency.settings.email_name,
        email_address: this.user.agency.settings.email_address,
        email_message: this.user.agency.settings.email_message,
        setting_form:'emails'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    handleExplain() {
      this.showDomainModal = true
    },
  },
}
</script>

<style lang="scss">
.settingsPage {
  .boxFile {
    .input-group {
      background-color: #4284f4;
      border-color: 1px solid #4284f4;
      border-radius: 0.3rem;
      color: #fff;
      input[type='file'] {
        cursor: pointer;
      }
      &::before {
        position: absolute;
        top: 9px;
        left: calc(50% - 45px);
        font-weight: 600;
        color: #fff;
        pointer-events: none;
        content: 'Upload File';
      }
      &:hover {
        background-color: #1e6cf2;
        border-color: #1265f1;
        &::before {
          color: #fff;
        }
      }
    }
    .form-control {
      opacity: 0;
    }
  }
}
</style>
